<template>
  <ul class="file-list">
    <li
      v-for="file in fileList"
      :key="config.oss_url + file.url"
      class="file-list__item"
      :style="'width:' + (testplanId || show_all ? '100%' : '50%')"
    >
      <div class="file_name">
        <el-tooltip placement="top" :disabled="!isShowTooltip">
          <div slot="content">{{ file.name }}</div>
          <span
            style="
              max-width: 100%;
              margin: 0;
              text-align: left;
              font-size: 17px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #409eff;
            "
            @mouseenter="visibilityChange($event)"
            >{{ file.name }}</span
          >
        </el-tooltip>
        <div>
          <svg
            v-if="isVisible(file)"
            xmlns="http://www.w3.org/2000/svg"
            class="show hover-icon"
            width="12"
            height="12"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            @click="showDialog(file)"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="12" r="2" />
            <path
              d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"
            />
          </svg>
        </div>
        <div class="hover-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            @click="download(config.oss_url + file.url, file)"
          >
            <defs>
              <rect id="path_0" x="0" y="0" width="16" height="16" />
            </defs>
            <g
              opacity="1"
              transform="translate(9.237055564881302e-14 9.947598300641403e-14)  rotate(0 8 8)"
            >
              <mask id="bg-mask-0" fill="white">
                <use xlink:href="#path_0"></use>
              </mask>
              <g mask="url(#bg-mask-0)">
                <path
                  id="路径 1"
                  fill-rule="evenodd"
                  style="fill: #808080"
                  transform="translate(0.5714285781249995 9.714285718750002)  rotate(0 7.4285714218750005 2.857142851562501)"
                  opacity="1"
                  d="M13.71,0L13.71,4.57L1.14,4.57L1.14,0L0,0L0,5.71L14.86,5.71L14.86,0L13.71,0Z "
                />
                <path
                  id="路径 2"
                  fill-rule="evenodd"
                  style="fill: #808080"
                  transform="translate(7.4285714218750005 0.5714285781250004)  rotate(0 0.5714285781249999 5.4285714218750005)"
                  opacity="1"
                  d="M1.14,10.86L1.14,0L0,0L0,10.86L1.14,10.86Z "
                />
                <path
                  id="路径 3"
                  fill-rule="evenodd"
                  style="fill: #808080"
                  transform="translate(4.5714285781249995 7.7714285781250005)  rotate(0 2 2.0000000000000004)"
                  opacity="1"
                  d="M4,3.2L0.8,0L0,0.8L3.2,4L4,3.2Z "
                />
                <path
                  id="路径 4"
                  fill-rule="evenodd"
                  style="fill: #808080"
                  transform="translate(7.199999999999999 7.7714285781250005)  rotate(0 2.1714285703125 2.1142857109375)"
                  opacity="1"
                  d="M4.34,0.8L3.54,0L0,3.43L0.8,4.23L4.34,0.8Z "
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div v-show="deletebtn" class="close" @click="deleteSelf(file)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 hover-icon"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </li>
    <el-dialog
      :append-to-body="true"
      :title="$t('templateLibrary.preview')"
      :visible.sync="dialogImgVisible"
      :close-on-click-modal="false"
      :show-close="true"
      width="50%"
    >
      <el-image
        :src="currentSrc"
        style="width: 100%; height: 100%"
        :preview-src-list="imgList"
      >
      </el-image>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogImgVisible = false">{{ $t('btn.cancelBtn') }}</el-button>
    <el-button type="primary" @click="dialogImgVisible = false">{{ $t('btn.newBtn') }}</el-button> -->
      </span>
    </el-dialog>
    <el-dialog
      :append-to-body="true"
      :title="$t('templateLibrary.preview')"
      fullscreen
      :visible.sync="dialogPdfVisible"
      :close-on-click-modal="false"
      :show-close="true"
      width="80%"
      :top="'1vh'"
    >
      <iframe :src="pdfSrc" style="width: 100%; height: 800px"></iframe>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogPdfVisible = false">{{ $t('btn.cancelBtn') }}</el-button>
    <el-button type="primary" @click="dialogPdfVisible = false">{{ $t('btn.newBtn') }}</el-button> -->
      </span>
    </el-dialog>
  </ul>
</template>

<script>
import {
  get_node_use_node_key,
  only_set_property,
} from "@/network/node/index.js";
import { execute_test_node } from "@/network/test/index.js";
import vmson from "@/utils/vmson";
import jsPDF from "jspdf";
import config from "@/../config/index";
export default {
  name: "FileList",
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    projectId: {
      type: String,
      default: "",
    },
    testplanId: {
      type: String,
      default: "",
    },
    nodeKey: {
      type: String,
      default: "",
    },
    deletebtn: {
      type: Boolean,
      default: true,
    },
    change_requests: {
      type: Boolean,
      default: false,
    },
    show_all: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogImgVisible: false,
      dialogPdfVisible: false,
      imgList: [],
      currentSrc: "",
      fileList: [],
      pdfSrc: "",
      isShowTooltip: false,
      config: config,
      officeUrl: "",
      showWord: false,
      showExcel: false,
      showPpt: false,
    };
  },
  watch: {
    files() {
      this.fileList = JSON.parse(JSON.stringify(this.files));
      this.fileList.forEach((file) => {
        if (this.isImg(file)) {
          this.imgList.push(config.oss_url + file.url);
        }
      });
    },
  },
  created() {
    this.fileList = JSON.parse(JSON.stringify(this.files));
    this.fileList.forEach((file) => {
      if (this.isImg(file)) {
        this.imgList.push(config.oss_url + file.url);
      }
    });
  },
  methods: {
    rendered() {},
    visibilityChange(event) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isShowTooltip = true;
      } else {
        // 否则为不溢出
        this.isShowTooltip = false;
      }
    },
    deleteSelf(deleteFile) {
      if (this.change_requests) {
        let arr = [];
        for (let i of this.fileList) {
          if (i.uid != deleteFile.uid) {
            arr.push(i);
          }
        }
        this.fileList = arr;
        vmson.$emit("update_change_requests_attachments_list", arr);
        return;
      }
      get_node_use_node_key(this.get_pid(), this.nodeKey).then((res) => {
        this.fileList = this.fileList.filter(
          (file) => file.url !== deleteFile.url
        );
        let nodedata = res;
        nodedata.attachments = this.fileList;
        const params = {
          projectId: this.get_pid(),
          nodeKey: nodedata.key,
          data: {
            isCustom: false,
            fieldId: "attachments",
            value: this.fileList,
          },
        };
        only_set_property(params).then((res) => {
          vmson.$emit("update_enclosure_table", this.fileList);
        });
      });
    },
    download(url, file) {
      window.open(url);
    },
    showDialog(file) {
      this.showExcel = false;
      this.showWord = false;
      this.showPpt = false;
      if (
        this.isPDF(file) ||
        this.isWord(file) ||
        this.isExcel(file) ||
        this.isPpt(file) ||
        this.isCad(file)
      ) {
        const url = `${
          window.location.origin
        }/kk/onlinePreview?url=${encodeURIComponent(
          btoa(
            (config.oss_url + file.url)
              .replaceAll("(", "%28")
              .replaceAll(")", "%29")
          )
        )}`;
        window.open(url, "_blank");
      } else {
        this.dialogImgVisible = true;
      }
      this.currentSrc = config.oss_url + file.url;
    },
    isVisible(file) {
      return (
        this.isImg(file) ||
        this.isPDF(file) ||
        this.isWord(file) ||
        this.isExcel(file) ||
        this.isPpt(file) ||
        this.isCad(file)
      );
    },
    isPDF(file) {
      return file.name.endsWith(".pdf") || file.name.endsWith(".PDF");
    },
    isWord(file) {
      return file.name.endsWith(".doc") || file.name.endsWith(".docx");
    },
    isExcel(file) {
      return file.name.endsWith(".xls") || file.name.endsWith(".xlsx");
    },
    isPpt(file) {
      return file.name.endsWith(".ppt") || file.name.endsWith(".pptx");
    },
    isCad(file) {
      return (
        file.name.endsWith(".dwg") ||
        file.name.endsWith(".dxf") ||
        file.name.endsWith(".dwf") ||
        file.name.endsWith(".iges") ||
        file.name.endsWith(".dwigsg") ||
        file.name.endsWith(".dwt") ||
        file.name.endsWith(".dng") ||
        file.name.endsWith(".ifc") ||
        file.name.endsWith(".dwfx") ||
        file.name.endsWith(".stl") ||
        file.name.endsWith(".cf2") ||
        file.name.endsWith(".plt")
      );
    },
    isImg(file) {
      return (
        file.name.endsWith(".png") ||
        file.name.endsWith(".jpg") ||
        file.name.endsWith(".jpeg") ||
        file.name.endsWith(".PNG") ||
        file.name.endsWith(".JPG") ||
        file.name.endsWith(".JPEG")
      );
    },
  },
};
</script>

<style scoped lang="scss">
@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(359deg);
  }
}

.rotate {
  animation: loading 0.8s infinite ease-in-out;
}

.file-list {
  list-style: none;
  padding-left: 0;
  margin-top: 12px;

  &__item {
    position: relative;
    padding: 6px 12px;

    .file_name {
      width: 90%;
      display: flex;
      gap: 12px;
      align-items: center;
    }

    &:hover {
      // background-color: #dee2e6;
    }

    // .close {
    //   position: absolute;
    //   right: 10px;
    // }

    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 18px;
    }

    svg {
      width: 18px !important;
      height: 18px !important;
      cursor: pointer;
      color: #666;
    }
  }
}
</style>
